/**
 * This handles landing page operations
 */
window._smpr = window._smpr || {};

window._smpr.util = function () {

    var setActiveView = function (v, views) {
        for (var view in views) {
            if (views[view] === v) {
                $('.' + views[view]).show();
            } else {
                $('.' + views[view]).hide();
            }
        }
    };

    var oPublic = {
        setActiveView: setActiveView
    };

    return oPublic;

}();